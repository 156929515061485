<template>
  <section id="docs" class="section_container">
    <v-container>
      <default-home-section-title :title="titleSection" />
      <v-row justify="space-around">
        <v-col cols="12" class="d-flex col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-card-text>
              <h2 class="text-center mb-5">Нормативно-правовые акты</h2>
              <p
                class="pl-5"
                style="color: white"
                v-for="doc in docs[0]"
                :key="doc.title"
              >
                <a
                  :href="doc.href"
                  class="section_link"
                  rel="noopener noreferrer"
                  target="_blank"
                  v-text="doc.title"
                />
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-card-text>
              <h2 class="text-center mb-5">Техническая документация</h2>
              <p
                class="pl-5"
                style="color: white"
                v-for="doc in docs[1]"
                :key="doc.title"
              >
                {{ doc.title }}
                <a
                  :href="doc.href"
                  class="section_link"
                  download
                  rel="noopener noreferrer"
                >
                  <!-- :download="doc.title" -->
                  <v-icon>{{ doc.icon }}</v-icon>
                </a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<style lang="scss" scoped>
.section_container {
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  // color: black;
  text-decoration: underline;
}
</style>
<script>
export default {
  name: "HomeSectionDocs",
  components: {
    DefaultHomeSectionTitle: () => import("@/views/Home/HomeSectionTitle.vue")
  },
  data() {
    return {
      titleSection: "Документация",
      // cardColor: "#5074b5",
      cardColor: "black",
      docs: [
        [
          {
            title: "Кодекс О недрах и недропользовании. Статья 144.",
            href: "https://adilet.zan.kz/rus/docs/K1700000125",
            icon: "mdi-download"
          },
          {
            title:
              "Об утверждении Перечня и сроков оснащения производственных объектов, подлежащих оснащению приборами учета сырой нефти и газового конденсата​",
            href: "https://adilet.zan.kz/rus/docs/V1900019559",
            icon: "mdi-download"
          },

          {
            title:
              "Правила оснащения производственных объектов приборами учета сырой нефти и газового конденсата и обеспечения функционирования приборов учета сырой нефти и газового конденсата",
            href: "https://adilet.zan.kz/rus/docs/V1900019664",
            icon: "mdi-download"
          }
        ],
        [
          {
            title:
              "Генеральная схема оснащения производственных объектов приборами учета",
            href: "/downloads/Scheme_Meters.pdf",
            icon: "mdi-download"
          },
          {
            title: "Инструкция - подключение к SmartBridge",
            href: "/downloads/Instructions_SmartBridge.pdf",
            icon: "mdi-download"
          },
          {
            title: "Инструкция - организация VPN канала",
            href: "/downloads/Instructions_VPN.pdf",
            icon: "mdi-download"
          },
          {
            title: "Технические требования",
            href: "/downloads/Technical_Requirements.pdf",
            icon: "mdi-download"
          }
        ]
      ]
    };
  }
};
</script>
